import BaseBean from "@/utils/BaseBean";

export interface IPersonCenterDataObj {
    utilInst:PersonCenterUtil
    refMap:Map<string,any>
    userInfo:any
}
export default class PersonCenterUtil extends BaseBean{
    public dataObj:IPersonCenterDataObj;

    constructor(proxy:any,dataObj:IPersonCenterDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
}