import {ref, reactive, toRefs, defineComponent,onBeforeMount,onMounted,getCurrentInstance} from 'vue';
import PersonCenterUtil ,{IPersonCenterDataObj} from './personCenterUtil';
export default defineComponent({
    name:'personCenter',
    beforeRouteEnter(to,from,next){
        if(sessionStorage.getItem('userInfo')){
            next();
        }else{
            next('/clientLogin');
        }
    },
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IPersonCenterDataObj=reactive<IPersonCenterDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            userInfo:{
                code:'',
                name:''
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new PersonCenterUtil(proxy,dataObj);
        })
        onMounted(()=>{
            if(sessionStorage.getItem('userInfo')){
                dataObj.userInfo=JSON.parse(sessionStorage.getItem('userInfo') as string);
            }
        })
        const backHandler=async ()=>{
            await proxy.$router.replace({path:'/clientRepairManual'});
        }
        const updatePwdHandler=async ()=>{
            await proxy.$router.replace({path:'/clientUpdateInfo'});
        }
        return{
            ...toRefs(dataObj),backHandler,updatePwdHandler
         
        }
    }
});